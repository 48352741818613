import React from "react";
import Header from "./header";
import Footer from "./footer";
import styled from "styled-components";
import styling from "../../styling";
import GlobalStyle from "./globalStyle";
import { Helmet } from "react-helmet";

const StyledContentArea = styled.div``;

const StyledContent = styled.main``;

interface Props {
  currentRoute: Routes;
}

const Layout: React.FC<Props> = (props) => {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ply5pdn.css" />
      </Helmet>
      <Header currentRoute={props.currentRoute} />
      <StyledContentArea>
        <StyledContent>{props.children}</StyledContent>
      </StyledContentArea>
      <Footer />
    </>
  );
};

export default Layout;
