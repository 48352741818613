import React, { useState } from "react";
import styled, { css } from "styled-components";
import styling from "../../styling";
import { Link } from "gatsby";
import LogoPath from "../../images/NXRT_Logo_Industries_Zeichenfläche 1 Kopie 6.svg";
import LogoAutomotivePath from "../../images/NXRT_Logo_Industries_Zeichenfläche 1 Kopie.svg";
import LogoRailConstructionPath from "../../images/NXRT_Logo_Industries_Zeichenfläche 1 Kopie 4.svg";
import LogoEsportsPath from "../../images/NXRT_Logo_Industries_Zeichenfläche 1 Kopie 3.svg";
import HamburgerIconPath from "../../images/bars-solid.svg";
import CloseIconPath from "../../images/times-regular.svg";
import LinkCursorImage from "../../images/cursor_link.png";

export enum Routes {
  Home,
  IndustryAutomotive,
  IndustryRailAndConstruction,
  IndustryESports,
  IndustryTraining,
  IndustryAviation,
}

const StyledHeaderArea = styled.div`
  padding: 0 1.5rem;
  background-color: #000d1d;
`;

const StyledHeader = styled.header`
  margin: 0 auto;
  width: 100%;
  max-width: ${styling.maxContentWidth};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLogo = styled.img`
  margin-right: 2rem;
  height: 12rem;
`;

const StyledLinks = styled.div``;

const StyledLink = styled(Link)<{ active: boolean }>`
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 2px solid;
  font-size: 1.7rem;
  font-weight: 100;
  cursor: url(${LinkCursorImage}) 17 21, auto;

  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
    `}

  :last-child {
    border-right: none;
  }

  @media (max-width: 72rem) {
    display: none;
  }
`;

const StyledHamburger = styled.div`
  cursor: pointer;

  > img {
    height: 3rem;
    filter: invert(1);
  }

  @media (min-width: 72rem) {
    display: none;
  }
`;

//#region Mobile
const StyledMobileContainer = styled.div<{ open: boolean }>`
  padding: 2rem;
  height: 100vh;
  width: 20rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  background-color: white;
  z-index: 1;
  transition: transform 0.5s ease-in-out;

  ${(props) =>
    props.open &&
    css`
      transform: translateX(0);
    `}

  a {
    color: black;
  }
`;

const StyledMobileLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMobileLink = styled(Link)<{ active: boolean }>`
  font-size: 1.5rem;
  line-height: 1.5;

  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
    `}
`;

const StyledMobileCloseButton = styled.button`
  align-self: flex-end;
  border: none;
  background: none;
  cursor: pointer;

  > img {
    width: 1rem;
  }
`;
//#endregion

const routeToLogo = {
  [Routes.Home]: LogoPath,
  [Routes.IndustryAutomotive]: LogoAutomotivePath,
  [Routes.IndustryRailAndConstruction]: LogoRailConstructionPath,
  [Routes.IndustryESports]: LogoEsportsPath,
  [Routes.IndustryAviation]: LogoPath,
  [Routes.IndustryTraining]: LogoPath,
};

interface Props {
  currentRoute: Routes;
}

const Header: React.FC<Props> = (props) => {
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);

  const logoPath = routeToLogo[props.currentRoute];

  return (
    <StyledHeaderArea>
      <StyledHeader>
        <Link to={"/"}>
          <StyledLogo src={logoPath}></StyledLogo>
        </Link>
        <StyledLinks>
          <StyledLink to={"/"} active={props.currentRoute === Routes.Home}>
            Home
          </StyledLink>
          <StyledLink
            to={"/automotive"}
            active={props.currentRoute === Routes.IndustryAutomotive}
          >
            Automotive
          </StyledLink>
          <StyledLink
            to={"/rail-and-construction"}
            active={props.currentRoute === Routes.IndustryRailAndConstruction}
          >
            Rail &amp; Construction
          </StyledLink>
          <StyledLink
            to={"/esports"}
            active={props.currentRoute === Routes.IndustryESports}
          >
            E-Sports
          </StyledLink>
          {/* <StyledLink to={"/training"}>Training</StyledLink>
          <StyledLink to={"/aviation"}>Aviation</StyledLink> */}
        </StyledLinks>
        <StyledHamburger onClick={() => setMobileHeaderOpen(true)}>
          <img src={HamburgerIconPath} />
        </StyledHamburger>
      </StyledHeader>
      <StyledMobileContainer open={mobileHeaderOpen}>
        <StyledMobileCloseButton onClick={() => setMobileHeaderOpen(false)}>
          <img src={CloseIconPath} />
        </StyledMobileCloseButton>
        <StyledMobileLinks>
          <StyledMobileLink
            to={"/"}
            onClick={() => setMobileHeaderOpen(false)}
            active={props.currentRoute === Routes.Home}
          >
            Home
          </StyledMobileLink>
          <StyledMobileLink
            to={"/automotive"}
            onClick={() => setMobileHeaderOpen(false)}
            active={props.currentRoute === Routes.IndustryAutomotive}
          >
            Automotive
          </StyledMobileLink>
          <StyledMobileLink
            to={"/rail-and-construction"}
            onClick={() => setMobileHeaderOpen(false)}
            active={props.currentRoute === Routes.IndustryRailAndConstruction}
          >
            Rail &amp; Construction
          </StyledMobileLink>
          <StyledMobileLink
            to={"/e-sports"}
            onClick={() => setMobileHeaderOpen(false)}
            active={props.currentRoute === Routes.IndustryESports}
          >
            E-Sports
          </StyledMobileLink>
          <StyledMobileLink
            to={"/training"}
            onClick={() => setMobileHeaderOpen(false)}
            active={props.currentRoute === Routes.IndustryTraining}
          >
            Training
          </StyledMobileLink>
          <StyledMobileLink
            to={"/aviation"}
            onClick={() => setMobileHeaderOpen(false)}
            active={props.currentRoute === Routes.IndustryAviation}
          >
            Aviation
          </StyledMobileLink>
        </StyledMobileLinks>
      </StyledMobileContainer>
    </StyledHeaderArea>
  );
};

export default Header;
