import React, { useState } from "react";
import styled, { css } from "styled-components";
import GoogleMapsImage1 from "../../images/google-maps-wien.png";
import GoogleMapsImage2 from "../../images/google-maps-moedling.png";
import styling from "../../styling";

const StyledFooterArea = styled.div`
  padding: 2rem 1.5rem;
  background-color: #000d1d;
`;

const StyledFooter = styled.footer`
  margin: 0 auto;
  width: 100%;
  max-width: ${styling.maxContentWidth};
  display: flex;
  align-items: flex-start;

  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 300;
  }

  @media (max-width: 72rem) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const StyledLocationContainer = styled.div`
  margin-bottom: 2rem;
  align-self: center;

  > h2 {
    text-align: center;
  }
`;

const StyledLocationLink = styled.a<{ active: boolean }>`
  display: ${(props) => (props.active ? "inline" : "none")};

  > img {
    width: 25rem;
    max-width: 100%;
  }
`;

const StyledLocationSelectContainer = styled.div`
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-around;
`;

const StyledLocationSelect = styled.div<{ active: boolean }>`
  cursor: pointer;

  ${(props) =>
    !props.active &&
    css`
      opacity: 0.6;
    `}

  > span {
    display: block;
    text-align: center;
  }

  > span:first-child {
    font-size: 1rem;
  }

  > span:last-child {
    font-size: 0.8rem;
  }
`;

const StyledLinkCollectonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const StyledLinkCollecton = styled.div`
  margin: 0 1rem 1rem 1rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;

  > h2 {
    margin-left: -1rem;
  }

  > a {
    font-size: 1.2rem;
    line-height: 1.5;
  }
`;

const Footer: React.FC = () => {
  const [selectedLocation, setSelectedLocation] = useState("vienna");

  return (
    <StyledFooterArea>
      <StyledFooter>
        <StyledLocationContainer>
          <StyledLocationLink
            href="https://www.google.at/maps/search/Freudenauer+Hafenstra%C3%9Fe+18%2F3,+1020+Wien"
            active={selectedLocation === "vienna"}
            target="_blank"
          >
            <img src={GoogleMapsImage1} />
          </StyledLocationLink>
          <StyledLocationLink
            href="https://www.google.at/maps/search/Wiener+Stra%C3%9Fe+13,+2340+M%C3%B6dling"
            active={selectedLocation === "moedling"}
            target="_blank"
          >
            <img src={GoogleMapsImage2} />
          </StyledLocationLink>
          <StyledLocationSelectContainer>
            <StyledLocationSelect
              active={selectedLocation === "vienna"}
              onClick={() => setSelectedLocation("vienna")}
            >
              <span>Freudenauer Hafenstr. 18/3</span>
              <span>1020 Vienna, Austria</span>
            </StyledLocationSelect>
            <StyledLocationSelect
              active={selectedLocation === "moedling"}
              onClick={() => setSelectedLocation("moedling")}
            >
              <span>Wiener Str. 13</span>
              <span>2340 Moedling, Austria</span>
            </StyledLocationSelect>
          </StyledLocationSelectContainer>
        </StyledLocationContainer>
        <StyledLinkCollectonContainer>
          <StyledLinkCollecton>
            <h2>Company</h2>
            <a href="">Contact</a>
            <a href="">Privacy</a>
            <a href="">Fineprint</a>
          </StyledLinkCollecton>
          <StyledLinkCollecton>
            <h2>Career</h2>
            <a href="">Job 1</a>
            <a href="">Job 2</a>
            <a href="">Job 3</a>
            <a href="">Job 4</a>
          </StyledLinkCollecton>
          <StyledLinkCollecton>
            <h2>Dive Deeper</h2>
            <a href="">LinkedIn</a>
            <a href="">Vimeo</a>
            <a href="">Twitter</a>
            <a href="">Facebook</a>
            <a href="">Instagram</a>
          </StyledLinkCollecton>
        </StyledLinkCollectonContainer>
      </StyledFooter>
    </StyledFooterArea>
  );
};

export default Footer;
