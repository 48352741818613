const styling = {
  maxContentWidth: "120rem",
  colors: {
    background: "#000000",
    textNormal: "#ffffff",
    textEmphasis: "#ffffff",
  },
};

export default styling;
